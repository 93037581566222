<template>
  <div style="background-color: #ffffff" class="review-rent-change-container">
    <hb-blade-header half @back="onClose" @close="onClose">
      <template v-slot:title>
        <span class="d-flex align-center">
          <svg
            width="20"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="mr-2"
          >
            <path
              d="M16.16 7.53005L9.09 14.6L4.85 10.36L6.26 8.95005L9.09 11.78L14.75 6.12005L16.16 7.53005ZM2.5 10C2.5 7.67005 3.52 5.58005 5.12 4.12005L7.5 6.50005V0.500049H1.5L3.7 2.70005C1.74 4.52005 0.5 7.11005 0.5 10C0.5 15.19 4.45 19.4501 9.5 19.9501V17.93C5.56 17.4401 2.5 14.07 2.5 10ZM20.5 10C20.5 4.81005 16.55 0.550049 11.5 0.0500488V2.07005C15.44 2.56005 18.5 5.93005 18.5 10C18.5 12.33 17.48 14.42 15.88 15.88L13.5 13.5V19.5H19.5L17.3 17.3C19.26 15.48 20.5 12.89 20.5 10Z"
              fill="#101318"
            />
          </svg>
          Review Rent Change Recommendations
        </span>
      </template>
    </hb-blade-header>
    <div class="pt-4 px-5" v-if="notification && notificationMessage">
      <hb-notification
        v-model="notification"
        :have-title="false"
        type="caution"
        @close="clearNotification()"
      >
        <span v-html="notificationMessage"></span>
      </hb-notification>
    </div>
    <div
      class="px-5 pb-4"
      :style="{ height: reportHeight + 'px' }"
      v-resize="setHeight"
    >
      <hb-report
        :key="0"
        :report_type="'review_rent_changes'"
        right_click
        row_click="unit_view"
        left_slot
        hide_back_link
        :grid-conf="gridConf"
        :column-conf="columnConf"
        :show_divider="false"
        :actions_panel="[
          'filters',
          'bulk_edit',
          'download',
          'upload',
          'report_options',
        ]"
        :default_search_filters="search_filter"
        :property="selectedProperty"
        @rowClicked="viewUnit($event)"
        @gridReady="(api) => (gridApi = api)"
      >
        <template v-slot:left>
          <div class="hb-font-header-1 mb-1" v-if="hasSummarizedMonthData">
            {{
              getFormatedDate(
                rentQueueData.rent_change_deployment_month,
                "MMM YYYY",
                "MMMM YYYY"
              )
            }}
            <span class="font-weight-regular"> ({{ getPropertyName }}) </span>
          </div>
          <div v-if="lastUpload" class="font-regular">{{ lastUpload }}</div>
        </template>
      </hb-report>
    </div>
    <hb-data-table
      v-if="hasSummarizedMonthData"
      class="px-5 pb-5"
      :headers="headers"
      :items="[queueData]"
      :style="approvalStyle(queueData)"
    >
      <template v-slot:item.rent_change_deployment_month="{ item }">
        <span class="font-weight-medium">
          {{
            getFormatedDate(
              item.rent_change_deployment_month,
              "MMM YYYY",
              "MMMM YYYY"
            )
          }}
        </span>
      </template>
      <template v-slot:item.rent_change_total_rent_increase="{ item }">
        <span class="font-weight-medium">
          {{ formatMoney(item.rent_change_total_rent_increase) }}
        </span>
      </template>
      <template v-slot:item.rent_change_store_occupancy="{ item }">
        {{
          isNotApplicable(item.rent_change_store_occupancy)
            ? "N/A"
            : `${(item.rent_change_store_occupancy * 100).toFixed(2)}%`
        }}
      </template>
      <template v-slot:item.rent_change_move_out_in_90_days="{ item }">
        {{
          isNotApplicable(item.rent_change_move_out_in_90_days)
            ? "N/A"
            : item.rent_change_move_out_in_90_days
        }}
      </template>
      <template v-slot:item.rent_change_variance_rent_sell="{ item }">
        <span>
          {{
            item.rent_change_variance_rent_sell
              ? (item.rent_change_variance_rent_sell * 100).toFixed(2)
              : 0
          }}%
        </span>
      </template>
      <template v-slot:item.rent_change_approval_deadline_date="{ item }">
        {{
          item.rent_change_approval_deadline_date != "N/A"
            ? getFormatedDate(
                item.rent_change_approval_deadline_date,
                "YYYY-MM-DD HH:mm:ss",
                "ll"
              )
            : item.rent_change_approval_deadline_date
        }}
      </template>
    </hb-data-table>

    <hb-bottom-action-bar class="bottom-action-bar" @close="onClose">
      <template v-slot:left-actions>
        <help-juice />
      </template>
      <template v-slot:right-actions>
        <hb-btn
          :disabled="disableApproveAll"
          :loading="approvalLoading"
          color="secondary"
          @click="approveRentChange({}, true)"
        >
          Approve All Rent Changes
        </hb-btn>
        <hb-btn
            :disabled="disableApproveAll"
            :loading="approvalLoading"
            color="secondary"
            @click="generateNotices({})">
          Generate Notices
        </hb-btn>

      </template>
    </hb-bottom-action-bar>
    <hb-modal
      v-model="showNoPermissionModal"
      size="medium"
      title="Unable to Approve Rent Changes"
      :footerOff="true"
      confirmation
      @close="clearRowRentChangeId()"
      show-help-link
    >
      <template v-slot:content>
        <div class="px-6 py-4">
          <div class="pb-2">
            The signed in account does not have permission to approve rent
            changes. To approve rent changes, please contact your administrator.
          </div>
        </div>
      </template>
    </hb-modal>

    <hb-modal
        v-model="showNoPermissionModalGenerateNotice"
        size="medium"
        title="Unable to Generate Rent Notices"
        :footerOff="true"
        confirmation
        @close="clearRowRentChangeId()"
        show-help-link
    >
      <template v-slot:content>
        <div class="px-6 py-4">
          <div class="pb-2">
            The signed in account does not have permission to generate rent
            notices. To generate rent notices, please contact your administrator.
          </div>
        </div>
      </template>
    </hb-modal>

    <!-- Exempt Rent Change -->
    <exempt-rent-change
      ref="exemptRent"
      :property="selectedProperty"
      @updateExemptStatus="updateExemptStatus($event)"
      @close="clearRowRentChangeId()"
    />
    <!-- Skip/Cancel Rent -->
    <skip-cancel-rent
      :property="selectedProperty"
      :skipCancelData="skipCancelData"
      :rent-change-id="getRentChangeId"
      :showSkipCancel="skipCancelNextRent"
      :report="true"
      @closeSkip="closeSkipCancel($event)"
    />
    <!-- Edit Rent change -->
    <rent-change-modal
      :ids="changeId"
      :rentData="rentChangeData"
      :currentRent="currentRent"
      :edit="true"
      title="Edit Rent Change"
      :manualRentChangeModal="showManualRentChangeModal"
      @closeModal="closeRentChange()"
    />
    <!-- Tag Rent Change -->
    <tag-rent-change
      ref="tagRent"
      @tagged="updateTagStatus($event)"
      @close="clearRowRentChangeId()"
    />
    <!-- View notes -->
    <view-rent-notes
      ref="viewRentNotes"
      @closeNotes="notesClosed($event)"
      :show-resolve="showResolve"
      :add-note="addNote"
    />
    <approve-rent-change
      ref="approveRentChange"
      @approved="rentChangeApproved($event)"
      @close="clearRowRentChangeId()"
    />

    <generate-notices ref="generateNotices"/>

  </div>
</template>

<script>
import { EventBus } from "../../../EventBus.js";
import { mapGetters, mapActions } from "vuex";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import { formatMoneyByCurrency } from "../../../utils/common";
import { cloneDeep } from "lodash";

import api from "@/assets/api.js";
import moment from "moment";

import HbReport from "@/components/assets/HummingbirdReportViewer.vue";
import UnitIconField from "../utils/UnitIconField.vue";

import MultiLineRenderer from "@/components/BI/MultiLineRenderer.vue";
import MultiLineStatusRenderer from "@/components/BI/MultiLineStatusRenderer.vue";
import ClickCellRenderer from "@/components/BI/ClickCellRenderer.vue";

import ReviewRentKebabFilter from "./ReviewRentKebabFilter.vue";
import ExemptRentChange from "./ExemptRentChange.vue";
import TagRentChange from "./TagRentChange.vue";
import ViewRentNotes from "./ViewRentNotes.vue";
import SkipCancelRent from "./SkipCancelRent.vue";
import RentChangeModal from "./RentChangeModal.vue";
import ApproveRentChange from "./ApproveRentChange.vue";
import GenerateNotices from "./GenerateNotices.vue";

export default {
  name: "ReviewRentChange",
  mixins: [notificationMixin],
  components: {
    ExemptRentChange,
    HbReport,
    SkipCancelRent,
    RentChangeModal,
    TagRentChange,
    ViewRentNotes,
    MultiLineRenderer,
    ClickCellRenderer,
    MultiLineStatusRenderer,
    ApproveRentChange,
    GenerateNotices,
  },
  props: {
    rentQueueData: {
      type: Object,
      default: () => ({}),
    },
    rmPermissions: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      gridApi: null,
      skipCancelData: {},
      notification: false,
      showManualRentChangeModal: false,
      rentChangeData: {},
      changeId: [],
      rentChangeId: "",
      currentRent: 0,
      skipCancelNextRent: false,
      reportHeight: 0,
      approvalLoading: false,
      approveLoading: false,
      headers: [
        {
          text: "Deployment Month",
          value: "rent_change_deployment_month",
          sortable: false,
          width: 139,
          column_type: "string",
          sort: 1,
        },
        {
          text: "Total Rent Changes",
          value: "rent_change_total_rent_changes",
          sortable: false,
          width: 100,
          column_type: "string",
          sort: 6,
        },
        {
          text: "Automated Rent Changes",
          value: "rent_change_system_rent_changes",
          sortable: false,
          width: 125,
          column_type: "string",
          sort: 7,
        },
        {
          text: "Manual Rent Changes",
          value: "rent_change_manual_rent_changes",
          sortable: false,
          width: 120,
          column_type: "string",
          sort: 8,
        },
        {
          value: "rent_change_deployed_rent_changes",
          width: 120,
          text: "Deployed Rent Changes",
          column_type: "number",
          sortable: false,
          sort: 9,
        },
        {
          value: "rent_change_approved_rent_changes",
          width: 120,
          text: "Approved Rent Changes",
          column_type: "number",
          sortable: false,
          sort: 10,
        },
        {
          value: "rent_change_scheduled_rent_changes",
          width: 120,
          text: "Scheduled Rent Changes",
          column_type: "number",
          sortable: false,
          sort: 11,
        },
        {
          value: "rent_change_cancelled_rent_changes",
          width: 120,
          text: "Cancelled Rent Changes",
          column_type: "number",
          sortable: false,
          sort: 12,
        },
        {
          value: "rent_change_skipped_rent_changes",
          width: 125,
          text: "Skipped Rent Changes",
          column_type: "number",
          sortable: false,
          sort: 13,
        },
        {
          text: "Move-out in 90 Days",
          value: "rent_change_move_out_in_90_days",
          sortable: false,
          width: 100,
          column_type: "percentage",
          sort: 5,
        },
        {
          text: "Over/Under Sell Rate",
          value: "rent_change_over_under_sell_rate",
          sortable: false,
          width: 70,
          column_type: "string",
          sort: 3,
        },
        {
          text: "Total Rent Increase",
          value: "rent_change_total_rent_increase",
          sortable: false,
          width: 100,
          column_type: "money",
          sort: 14,
        },
        {
          text: "Approval Deadline",
          value: "rent_change_approval_deadline_date",
          sortable: false,
          width: 125,
          column_type: "date",
          sort: 15,
        },
      ],
      metaData: {},
      showNoPermissionModal: false,
      showNoPermissionModalGenerateNotice: false,
      queueData: {},
      search_filter: [
        {
          id: "rent_change_deployment_month",
          value: this.rentQueueData.rent_change_deployment_month,
        },
      ], // for default searchfilter
      showResolve: false,
      addNote: false,
    };
  },
  watch: {
    customVariance: {
      handler(val) {
        this.notification = cloneDeep(val.active);
      },
      immediate: true,
      deep: true,
    },
    notification: {
      handler() {
        this.setHeight();
      },
      immediate: true,
    },
    getStructureData: {
      deep: true,
      immediate: true,
      handler() {
        this.getMonthDetails();
      },
    },
  },
  mounted() {
    this.getCustomVariance();
    this.$store.commit("reportStore/setRentChangeUploadHistory", {});
    this.getDownloadUploadHistory();
    let events = {
      rentChangeExempt: this.rentChangeExempt,
      editRentChange: this.editRentChange,
      refreshTable: this.triggerTableRefresh,
      skipCancelRentChange: this.skipCancelRentChange,
      viewNotes: this.viewNotes,
      tagRentChange: this.tagRentChange,
      approveRentChange: this.approveRentChange,
      resolveNotificationStatus: this.resolveNotificationStatus,
    };
    EventBus.$on("reviewRentChangeEvents", ({ event, data }) => {
      if (events[event]) events[event](data);
    });
    this.$store.commit(
      "reportStore/setTenantReportViews",
      "review_rent_changes"
    );
  },

  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered",
      customVariance: "reportStore/getCustomVariance",
      getRentChangeUploadHistory: "reportStore/getRentChangeUploadHistory",
      hasInternalRentEngine: "revManStore/hasInternalRentEngine",
      getStructure: "reportStore/getStructure",
      selectedProperty: "revManStore/getSelectedProperty",
      getPropertyRentSettings: "revManStore/getPropertyRentSettings",
    }),
    getStructureData() {
      return this.getStructure("rent_change_deployment_month");
    },
    disableApproveAll() {
      return this.queueData.rent_change_approval_status !== "To Approve";
    },
    getPropertyName() {
      let propertyDetails = this.properties.find(
        (property) => property.id === this.selectedProperty
      );
      return propertyDetails.name;
    },
    notificationMessage() {
      let date = this.customVariance?.date
        ? this.getFormatedDate(
            this.customVariance?.date,
            "YYYY-MM-DD",
            "MMM DD, YYYY"
          )
        : "";
      return this.customVariance?.active
        ? `Variance calculated from ${date} `
        : "";
    },
    hasSummarizedMonthData() {
      return !!Object.keys(this.rentQueueData).length;
    },
    gridConf() {
      return {
        props: {
          BIHelpers: {
            kebab: {
              enabled: true,
              icon: "mdi-dots-vertical",
              component: {
                definition: ReviewRentKebabFilter,
                props: {
                  parentEvent: "reviewRentChangeEvents",
                  permissions: this.rmPermissions,
                },
              },
              action: "click",
            },
          },
          getRowId: (params) => params.data.rentchange_id,
          defaultColDef: {
            floatingFilter: false,
            wrapHeaderText: true,
            autoHeaderHeight: true,
          },
          rowClassRules: {
            "hummingbird-tagged-row": (params) => {
              return !!params?.data?.rentchange_tagged;
            },
          },
          overlayNoRowsTemplate:
            '<span style="font-size: 20px;padding: 10px;">No Rent Changes</span>',
        },
      };
    },
    columnConf() {
      return {
        rentchange_tenant_name: {
          cellRenderer: "MultiLineRenderer",
          cellRendererParams: {
            fields: [
              {
                column: "rentchange_tenant_name",
                tooltip: true,
              },
              {
                column: "tenant_space_count",
                color: "#8E8E8F",
                tooltip: true,
                formatter: (val) =>
                  val ? (val > 1 ? `${val} Spaces` : `${val} Space`) : ''
              },
            ],
          },
          pinned: "left",
        },
        tenant_space_count: {
          hide: true,
        },
        rentchange_unit_number: {
          cellRenderer: "MultiLineRenderer",
          cellRendererParams: {
            fields: [
              {
                column: "rentchange_unit_number",
                tooltip: true,
                component: {
                  component: UnitIconField,
                  props: {
                    position: "left",
                    applyStroke: false,
                    small: true,
                    width: 16,
                    height: 16,
                    color: "#8E8E8F",
                    marginBottom: "0px",
                    verticalAlign: "middle",
                  },
                },
              },
              {
                column: "unit_size",
                color: "#8E8E8F",
                tooltip: true,
              },
            ],
          },
          pinned: "left",
        },
        unit_type: {
          hide: true,
        },
        lease_rent_plan_status: {
          cellRenderer: "StatusCellRenderer",
          cellRendererParams: {
            leaseStatus: true,
            general: true,
          },
        },
        unit_size: {
          hide: true,
        },

        tenant_selected_rent_plan: {
          hide:
            !this.hasInternalRentEngine ||
            !this.getPropertyRentSettings?.automation_enabled_by_admin,
          cellRenderer: (params) =>
            params.valueFormatted != "-" ? params.valueFormatted : "",
        },

        rentchange_notification_status: {
          cellRenderer: "MultiLineStatusRenderer",
          cellRendererParams: {
            fields: [
              {
                column: "rentchange_notification_status",
                type: "status",
              },
              {
                column: "rentchange_notification_sent",
                color: "#8E8E8F",
              },
            ],
          },
          pinned: "right",
          minWidth: 120,
        },

        unit_price: {
          pinned: "right",
        },

        rentchange_old_rent: {
          pinned: "right",
        },

        rentchange_new_rent: {
          cellRenderer: "MultiLineRenderer",
          cellRendererParams: {
            fields: [
              {
                column: "rentchange_new_rent",
                strong: true,
              },
              {
                column: "rentchange_rent_variance_combined",
                color: (data) => {
                  let [x, y] =
                    data.rentchange_rent_variance_combined.split("/");
                  return x
                    ? Number(x) === 0
                      ? "grey"
                      : Number(x) < 0
                      ? "red"
                      : "green"
                    : "";
                },
                formatter: (val) => {
                  if (!val) return "";
                  const [x, y] = val.split("/");
                  return `${
                    x
                      ? (Number(x) < 0 ? "-$" : "+$") +
                        Math.abs(Number(x)).toFixed(2)
                      : ""
                  }${x && y ? "/" : ""}${
                    y
                      ? (Number(y) < 0 ? "-" : "+") +
                        Math.abs(Number(y) * 100).toFixed(2) +
                        "%"
                      : ""
                  }`;
                },
              },
            ],
          },
          pinned: "right",
        },

        rentchange_effective_date: {
          pinned: "right",
        },

        rentchange_recent_note: {
          filter: false,
          cellRenderer: "ClickCellRenderer",
          cellRendererParams: {
            type: "link",
            formatter: (val) => (val ? "View Notes" : "Add Notes"),
            event: {
              name: "reviewRentChangeEvents",
              method: "viewNotes",
            },
          },
          pinned: "right",
        },

        rentchange_status: {
          cellRenderer: "MultiLineStatusRenderer",
          cellRendererParams: {
            fields: [
              {
                column: "rentchange_status",
                type: "status",
              },
              {
                column: "rentchange_status_modification_date",
                color: "#8E8E8F",
              },
            ],
          },
          pinned: "right",
          minWidth: 120,
        },
      };
    },

    getRentChangeId() {
      return this.skipCancelData?.rentchange_id ?? "";
    },

    lastActionInfo() {
      return this.getRentChangeUploadHistory;
    },

    lastUpload() {
      let uploadNote = "";
      if (this.lastActionInfo["upload"]?.action_at) {
        uploadNote = `Last Uploaded On: ${moment(
          this.lastActionInfo["upload"].action_at,
          "YYYY-MM-DD HH:mm:ss"
        ).format("MMM DD, YYYY")} by ${
          this.lastActionInfo["upload"].action_by
        }`;
      }
      return uploadNote;
    },

    deploymentMonth() {
      let month = this.rentQueueData?.rent_change_deployment_month;
      return moment(month, "MMM YYYY").isValid()
        ? moment(month, "MMM YYYY").format("YYYY_MM")
        : "";
    },
  },
  async created() {
    EventBus.$on("hide_drawer", this.onClose);
    EventBus.$on(
      "fetch_upload_download_history",
      this.getDownloadUploadHistory
    );
    this.setHeight();
  },
  beforeDestroy() {
    this.updateCustomVariance({});
    EventBus.$off("reviewRentChangeEvents");
  },
  destroyed() {
    EventBus.$off("hide_drawer", this.onClose);
    EventBus.$off(
      "fetch_upload_download_history",
      this.getDownloadUploadHistory
    );
  },
  methods: {
    ...mapActions({
      updateCustomVariance: "reportStore/updateCustomVariance",
    }),

    resolveNotificationStatus(data) {
      this.rentChangeId = data.rentchange_id;
      this.showResolve = true;
      this.addNote = true;
      this.$nextTick(async () => {
        await this.$refs.viewRentNotes.show({
          rowData: data,
          property: this.selectedProperty,
        });
      });
    },
    isNotApplicable(value) {
      return value ? (value.toLowerCase() === "n/a" ? true : false) : false;
    },

    clearRowRentChangeId() {
      if (this.showNoPermissionModal) this.showNoPermissionModal = false;
      if (this.showNoPermissionModalGenerateNotice) this.showNoPermissionModalGenerateNotice = false;
      this.updateRowData(this.rentChangeId);
      this.rentChangeId = "";
    },

    async getMonthDetails() {
      this.$nextTick(() => {
        this.queueData = {};
        let data = cloneDeep(this.getStructureData);
        let monthData = data?.views[0].table_data.find(
          (item) =>
            item.rent_change_deployment_month ===
            this.rentQueueData.rent_change_deployment_month
        );
        this.queueData = { ...monthData };
      });
    },

    clearNotification() {
      this.notification = false;
      this.setHeight();
    },
    notesClosed(notesData) {
      this.addNote = false;
      if (this.showResolve) {
        this.showResolve = false;
      }
      if (notesData?.note)
        this.updateRowData(notesData.rentchange_id, {
          rentchange_recent_note: notesData.note,
        });
      else this.clearRowRentChangeId();
    },

    async getCustomVariance() {
      let url =
        api.getPropertyRentManagementUrl(this.selectedProperty) +
        "custom-variance";
      await api
        .get(this, url)
        .then((r) => {
          let payload = {
            active: r?.custom_variance?.active,
            date: r?.custom_variance?.date,
          };
          this.updateCustomVariance(payload);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    onClose() {
      this.$emit("close");
    },

    formatMoney(money) {
      return formatMoneyByCurrency(money);
    },

    closeSkipCancel(data) {
      this.skipCancelNextRent = false;
      if (data.status) {
        this.triggerTableRefresh();
        EventBus.$emit("rentChangeQueueEvents", "refreshTable");
        EventBus.$emit("tenantRentManagementEvent", {
          event: "refreshTable",
        });
      } else this.clearRowRentChangeId();
    },

    setDynamicRuntimeProperties(data = null) {
      this.$store.commit("reportStore/setDynamicRunParam", {
        propertyIDArray: data ? [data] : undefined,
      });
    },

    /**
     *
     * function to refresh report viewer  columns
     */
    triggerTableRefresh(data = {}, columns = []) {
      if (!this.$route.query.dynamicRun) {
        this.$router.push({ path: "", query: { dynamicRun: true } });
      }
      this.setDynamicRuntimeProperties(this.selectedProperty);
      EventBus.$emit("unit_edited");
      this.getDownloadUploadHistory();
    },

    /**
     *
     * function to update row in reportviewer
     */
    updateRowData(pKey, data = {}) {
      let node = null;
      if (this.gridApi) node = this.gridApi.getRowNode(pKey);
      if (node) node.setData({ ...node.data, ...data });
    },
    /**
     * Function to open rent exempt modal
     */
    async rentChangeExempt(data) {
      this.rentChangeId = data.rentchange_id;
      await this.$refs.exemptRent.show(
        {
          ...data,
          tenant_name: data?.rentchange_tenant_name ?? "",
        },
        data?.lease_id,
        data.rentchange_id,
        data?.lease_rent_plan_status
      );
    },

    async updateExemptStatus(data) {
      this.showMessageNotification({
        type: data.type,
        description: data.description,
      });
      if (data.type === "success") {
        // If hummingbird rent engine is being used
        if (this.hasInternalRentEngine) {
          /**
           * Determine the lease rent plan status based on the rent plan and status type
           *  if status type is active and there are is rent plan clear the field else update status type
           */
          const lease_rent_plan_status =
            (!data.rentPlan || data.rentPlan === "-") &&
            data.statusType === "active"
              ? ""
              : data.statusType;
          // Update the row data with the new lease rent plan status
          this.updateRowData(data.rentChangeId, { lease_rent_plan_status });
        } else if (data.statusType === "exempt")
          await this.triggerTableRefresh();
        else
          this.updateRowData(data.rentChangeId, {
            lease_rent_plan_status: data.statusType,
          });
      } else {
        this.clearRowRentChangeId();
      }
    },

    async updateTagStatus(status) {
      if (status) {
        await this.triggerTableRefresh();
      }
    },

    getApprovalConfirmationMessage(status, data) {
      return `Are you sure you want to <span class="font-weight-medium">${
        status ? "Approve All Rent Changes" : "Approve Rent Change"
      }</span> for <span class="font-weight-medium">${
        status
          ? this.getFormatedDate(
              this.rentQueueData.rent_change_deployment_month,
              "MMM YYYY",
              "MMMM YYYY"
            )
          : data.rentchange_tenant_name
      }</span>?`;
    },

    async approveRentChange(data, byMonth = false) {
      if (!byMonth) this.rentChangeId = data.rentchange_id;
      if (!this.rmPermissions.approve_rent_changes) {
        this.showNoPermissionModal = true;
        return;
      }
      await this.$refs.approveRentChange.show({
        rowData: data,
        rentQueueData: this.rentQueueData,
        approveByMonth: byMonth,
      });
    },

    async generateNotices(data) {
      console.log(this.rmPermissions);
      if (!this.rmPermissions.generate_notices) { //TODO permissions
        this.showNoPermissionModalGenerateNotice = true;
        return;
      }
      await this.$refs.generateNotices.show({
        rowData: data,
        rentQueueData: this.rentQueueData
      });
    },

    async rentChangeApproved(approveData) {
      if (approveData.approved)
        this.updateRowData(approveData.rentchange_id, {
          rentchange_status: "Approved",
          rentchange_status_modification_date: moment().format("L"),
        });
    },

    getRentSellVariancePercent(sellRate, rent) {
      const variance = rent - sellRate;
      return sellRate ? (variance / sellRate) * 100 : null;
    },

    async editRentChange(data) {
      this.showManualRentChangeModal = true;
      this.rentChangeId = data.rentchange_id;
      this.changeId = [data.rentchange_id];
      this.rentChangeData = {
        lastChange: data.lease_last_rent_change_date,
        newRent: data.rentchange_new_rent,
        deploymentDate: data.rentchange_effective_date,
        affectAutomaticRent: !!data.rentchange_affect_timeline,
        sellRate: data.unit_price,
        setRate: data.unit_set_rate,
        variance: data.rentchange_old_rent - data.unit_price,
        variancePrct: this.getRentSellVariancePercent(
          data.unit_price,
          data.rentchange_old_rent
        ),
        notificationDate: data.rentchange_notification_sent,
      };
      this.currentRent = data.rentchange_current_rent;
    },

    async closeRentChange() {
      this.showManualRentChangeModal = false;
      this.clearRowRentChangeId();
    },

    async tagRentChange(data) {
      this.rentChangeId = data.rentchange_id;
      await this.$refs.tagRent.show(data);
    },

    async viewNotes(data) {
      this.rentChangeId = data.rentchange_id;
      await this.$refs.viewRentNotes.show({
        rowData: data,
        property: this.selectedProperty,
      });
    },

    async skipCancelRentChange(data) {
      this.rentChangeId = data.rentchange_id;
      this.skipCancelData = data;
      this.skipCancelNextRent = true;
    },

    viewUnit(data) {
      this.$store.dispatch("navigationStore/openSlideOut", {
        component: "unit",
        props: {
          unit_id: data.unit_id,
        },
      });
    },
    setHeight() {
      let header = 72;
      let bladeHeader = 66;
      let dataTable = 112;
      let bottomActionBar = 58;
      let notificationBar =
        this.notification && this.notificationMessage ? 58 : 0;
      this.reportHeight =
        window.innerHeight -
        header -
        bladeHeader -
        dataTable -
        bottomActionBar -
        notificationBar;
    },
    approvalStyle(data) {
      return data.rent_change_total_rent_increase > 0
        ? { "--bgColor": "#E1FAE3" }
        : data.rent_change_total_rent_increase < 0
        ? { "--bgColor": "rgba(217, 30, 24, 0.1)" }
        : "";
    },
    getFormatedDate(date, inputFormat, outputFormat) {
      return date ? moment(date, inputFormat).format(outputFormat) : "";
    },
    async getDownloadUploadHistory() {
      let url = `${api.getPropertyRentManagementUrl(
        this.selectedProperty
      )}rent-change/meta-info?month=${this.deploymentMonth}`;

      let response = await api.get(this, url);
      this.$store.commit("reportStore/setRentChangeUploadHistory", response);
    },
  },
};
</script>
<style lang="scss">
.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:nth-last-child(2) {
  background: var(--bgColor) !important;
}
.hb-data-table-wrapper.v-data-table tbody tr:hover {
  background: white !important;
}
</style>
