<template>
    <div>
        <div class="services-section scrollable-content scroll-products pr-4" style="overflow: auto;"> 
          <div class="checkout-options">
            <div class="hb-font-header-2-medium mt-1 mb-3">Checkout</div>
            <hb-radio-group row v-model="isGuestUser">
                <hb-radio label="Checkout as guest" :value="true"></hb-radio>
                <hb-radio label="Checkout as tenant" :value="false"></hb-radio>
              </hb-radio-group>

          </div>
          <payment-process 
            :contactID="contact.id" 
            :propertyID="property.id"
            :contact_prop.sync="contact"  
            :invoice="invoice"
            :guest-merchant="isGuestUser"
            paymentSource="SERVICES"
            :checkPaymentsErrors="checkPaymentsErrors"
            ref="paymentProcess">
          </payment-process>
        </div>

        <div class="new-tenant-walkthrough-footer" v-if="isGuestUser">
          <hb-bottom-action-bar
            @close="$emit('close')"
            :top-border="false"
          >
            <template v-slot:left-actions>
              <help-juice />
            </template>
            <template v-slot:right-actions>
              <hb-btn color="primary" :loading="disable_payment" :disabled="isLoading($options.name) || disable_payment" @click="checkPaymentsErrors">Process Payment</hb-btn>
            </template>
          </hb-bottom-action-bar>
        </div>
    </div>

</template>


<script type="text/babel">

    import api from '../../../assets/api.js';
    import Status from '../../includes/Messages.vue';
    import {charges} from '../../../mixins/leaseCharges.js'
    import moment from 'moment';
    import { EventBus } from '../../../EventBus.js';
    import PaymentProcess from '../PaymentProcess/Index.vue';
    import { mapActions, mapGetters, mapMutations } from 'vuex';
    import { notificationMixin } from  '../../../mixins/notificationMixin.js';

    export default {
        name: "Payments",
        data() {
            return {
                collect_payments: 0,
                panel: 0,
                payment_methods: [],
                auto_charge:false,
                prepay: null,
                show_charge: null,
                lease:{},
                payment_method: {
                    id: '',
                    type: '',
                    first: '',
                    last: '',
                    name_on_card: '',
                    card_number: '',
                    cvv2: '',
                    exp_mo: '',
                    exp_yr: '',
                    save_to_account: 1,
                    account_type: '',
                    routing_number: '',
                    account_number: '',
                    address: '',
                    address2: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: ''
                },
                payment: {
                    id: '',
                    property_id: '',
                    contact_id: '',
                    payment_method_id: '',
                    type: '',
                    credit_type:'',
                    number:'',
                    ref_name: '',
                    source: '',
                    amount: '',
                    date: '',
                    transaction_id: '',
                    amt_remaining: ''
                },
                isGuestUser: true
            }
        },
        props: ['contact',  'property', 'services', 'invoice', 'disable_payment'],
        components:{
            Status,
            PaymentProcess
        },
        created (){

            this.payment.property_id = this.property.id;
            this.payment.contact_id = this.contact.id;
            //this.calculateTotal();
        },
        computed: {
          ...mapGetters({
            getErrors: 'paymentsStore/getErrors'
          })
        },
        destroyed(){
          this.closeMessageNotification();
        },
        filters:{
        },
        methods: {
            ...mapActions({
                getTransformedPaymentObject: 'paymentsStore/getTransformedPaymentObject',
            }),
            ...mapMutations({
              setCheckErrors: 'paymentsStore/setCheckErrors',
              checkOnlyCredit: 'paymentsStore/checkOnlyCredit'
            }),
            checkPaymentsErrors() {
              //this.setCheckErrors({ value: true });
              //this function will check if the payment only with credit it will skip the checkErrors. otherwise, if not credit payment it wich check the errors normal.
              this.checkOnlyCredit();
            },
            async payNow(){

              const paymentInfo = await this.getTransformedPaymentObject({
                id: this.$options.name,
              });

              if(paymentInfo.errors && paymentInfo.errors.length) {
                this.showMessageNotification({ list: paymentInfo.errors });
                return;
              }

              this.$emit('processPayment', paymentInfo);
            },
            showCharges(product_id){
                this.show_charge = this.show_charge === product_id ? null : product_id;
            },


            // saved(){
            //     this.$emit('saved');
            // }
        },
        mixins:[charges, notificationMixin],
        watch: {
          getErrors(value) {
            if(value === false) {
              this.payNow();
              this.setCheckErrors({ value: null });

            }
          },
          isGuestUser(params) {
            this.$emit('isGuestUserEvent')
          }
        }
    }
</script>

<style scoped>

    .new-tenant-walkthrough-footer{
        padding: 3px 0 3px 30px;
        background: #FFFFFF;
        border-top: 1px solid #e2e2e2;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 100;
        left: 0;
        right: 0;
    }
    .services-section{
        padding: 5px 0px 24px;
    }

    .unit-confirm-icon .v-list-item__icon {
        text-align: center;
        background: white;
        padding: 25px;

    }

    .v-expansion-panel-content .form-label{
        background: #F4F6F8;
        padding: 20px;
        font-weight: bold;
    }
    .row {
        margin-bottom: 10px;
    }

    .payments-container{
        border: 1px solid #C4CDD5;
        background: white;
        border-radius: 4px;
        padding: 30px 50px;
    }
    .v-expansion-panel-content__wrap,
    .v-expansion-panel-content.no-padding .v-expansion-panel-content__wrap{
        padding: 0;
    }
    .v-expansion-panel-content__wrap {
        padding: 0px;
    }

    .invoice_display{
        padding: 20px;
        font-size: 14px;
    }
    .breakdown{
        display:inline-block;
        flex:0 0 100%;
        padding: 10px;
        width: 100%;
        background:#f2f9fd;
        border: 1px solid #dce8ef;
    }
    .new-tenant-form-data{
      border: 1px solid #DFE3E8;
    }

    .v-expansion-panel-content .form-label{
      background: #F4F6F8;
      padding: 20px;
      font-weight: bold;
    }

    .invoice_display{
      padding:  10px 0px;
      font-size: 14px;
    }

    .invoice-line-row{
      margin-bottom: 0;
      padding: 0px 20px;
    }
    .invoice-line-row .col {
      padding: 0;
    }

    .invoice-total-row >.col{
      padding: 10px;
    }

    .invoice-row .caption{
      color: #637381
    }

    .invoice-row > .col{
      padding-bottom: 0;
    }

    .invoice-line-row.total{
      border-top: 1px solid #C4CDD5;
      margin-top: 20px;
    }
    .invoice-line-row.total > .col{
      padding-top: 15px;
    }


    .payment-data-header .v-input--checkbox,
    .form-label .v-input--checkbox{
      margin-top: 0;
      padding-top: 0;
    }

    .payment-data-header{
      border-bottom: 1px solid #DFE3E8;
      padding: 15px 20px;
      margin-bottom: 0;
    }

    .container.payment-data-container {
      padding: 0 12px;
    }

    .card-options{
      border-right: 1px solid #C4CDD5;
      padding: 10px;
      font-size: 14px;
    }

    .payment-method-holder{
      padding: 10px;
    }

    .scroll-products {
        height: calc(100vh - 375px);
    }
</style>
