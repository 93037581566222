<template >
    <div class="pa-0 d-flex" style="flex-direction: column; align-items: stretch; width: 100%"> 
        <hb-blade-header
            half
            :back-button-off="activeSection.name === 'all'"
            @back="moveBack"
            @close="close(true)"
        >
            <template v-slot:right>
                <help-juice blade-header />
            </template>
            <template v-slot:title>
                <div class="d-flex align-center">
                  
                    <div v-if="tasksCount[activeSection.name] && activeSection.name !== 'all'" :class="activeSection.icon.mdi === 'mdi-system-navigation-custom-1' ? 'mt-1' : 'mt-n1'">
                        <v-badge
                            :content="tasksCount[activeSection.name].count"
                            :value="tasksCount[activeSection.name].count"
                            :color="tasksCount[activeSection.name].count !== 0 ? '#f26500' : '#02ad0f'"
                            overlap
                            offset-y="12"
                            offset-x="11"
                            bordered
                            >
                                <hb-icon color="#101318">
                                    {{ activeSection.icon.mdi }}
                                </hb-icon>
                        </v-badge>

                    </div>
                    <div v-else-if="activeSection.name === 'all'" :class="activeSection.icon.mdi === 'mdi-system-navigation-custom-1' ? 'mt-1' : 'mt-n1'">
                        <hb-icon color="#101318">
                            {{ activeSection.icon.mdi }}
                        </hb-icon>
                    </div>
                    <div v-else>
                        &nbsp;
                    </div>
                    <div class="ml-4">{{ activeSection.title }}</div>
                </div>
            </template>
        </hb-blade-header>
        <div style="flex: 0 1" v-show="activeSection.name === 'all'">
            <buttons></buttons>
            <v-divider></v-divider>
        </div>

        <div style="flex: 0 1" v-if="activeSection.name === 'delinquency' && isLockCategory">

            <v-row class="ma-0" >
            
                <v-col class="py-4 px-6 hb-font-caption	d-flex">
                    All completed tasks will clear out at midnight. Once a task is completed it cannot be undone
                </v-col>
            </v-row> 

            <v-row class="ma-0" >
            
                <v-col class="hb-table-label pa-2 py-4 d-flex align-center">
                    <div class="float-left pl-4 hb-font-header-3-medium ">Today: {{ currentDate }}</div>
                    <v-spacer></v-spacer>
                    <div class="float-right">
                        <hb-btn small color="secondary" @click="completeAll" class="mr-3">
                            Complete All
                        </hb-btn>
                        <hb-btn small color="secondary" @click="downloadReport" class="mr-1">
                            Download
                        </hb-btn>
                    </div>
                </v-col>
            </v-row> 
        </div>


        <div style="flex: 0 1" v-if="activeSection.name === 'all'">
            <v-row class="ma-0" >
                <v-col md="2" class="tasks-counter d-flex justify-center align-center mxw-83px" :class="{ 'success' : !numTasks }">
                    <div class="text-center pt-1">
                        {{ numTasks }}<br/>
                        <small>TASKS</small>
                    </div>
                </v-col>

                <v-col md="10" class="hb-table-label pa-2 d-flex align-center">
                    <div class="task-calender float-left pl-4">Today</div>
                    <v-spacer></v-spacer>
                    <div class="menu-options float-right">
                        <v-menu v-model="showFilters" left max-width="300" :close-on-content-click="false" content-class="hb-elevation-large">
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" @click="tempFilters = filters">
                                    <v-icon>mdi-filter-variant</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title class="py-3">
                                    <v-icon class="mr-2">mdi-filter-variant</v-icon> Filter
                                    <v-spacer></v-spacer>
                                    <v-icon @click="closeFilter()">mdi-close</v-icon>
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text class="pa-3 pt-1" style="height: 250px;overflow: auto;">
                                    <v-checkbox v-model="tempFilters" v-for="(item, i) in uniqueEvents" :value="item.id" :key="i" hide-details class="mt-2 pt-0" :label="item.name" />
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions class="px-6 py-3">
                                    <hb-btn color="primary" small :block="true" @click="applyFilters(true)">Apply</hb-btn>
                                </v-card-actions>
                            </v-card>
                        </v-menu>
                        <v-btn icon @click="showTaskDialog()">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <template v-if="filters.length">
            <v-container style="flex: 0 1" class="py-1">
                <v-row dense>
                    <v-col class="pa-0 my-1">
                        <hb-chip v-for="(filter, index) in filters" :key="index" editable @chip-click="refetchData(filter)">
                            {{ getEventName(filter) }}
                        </hb-chip>
                    </v-col>
                </v-row>
            </v-container>
            <v-divider></v-divider>
            </template>
        </div>




         <to-do v-model="showDialog" v-if="showDialog" @close="getTasks"></to-do>
        <tasks-list :pendingMoveIn="pending"  />
        <hb-modal
            size="medium"
            title="Complete All Tasks"
            v-model="showCompleteAllModal"
            confirmation
            show-help-link
        >
            <template v-slot:content>
                <div class="pa-5">You are about to complete all the tasks. This action cannot be undone. <br /><br />
                Are you sure you want to complete all the tasks? </div>
            </template>
            <template v-slot:actions>
                <hb-btn color="primary" @click="completeAllConfirm">Confirm</hb-btn>
            </template>
        </hb-modal>
    
        
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import TaskItem from './TaskItem';
    import ToDo from "../Todo.vue";
    import TasksList from './TasksList';
    import Buttons from './Buttons';
    import Header from './Header';
    import api from '../../../assets/api.js'
    import moment from 'moment';
    import { EventBus } from '../../../EventBus.js';
    import { notificationMixin } from  '../../../mixins/notificationMixin.js';

    export default {
        name: "TaskCenter",
        mixins: [ notificationMixin ],
        data() {
            return {
                pending: [],
                tempTask: [],
                urlEventIds: {},
                disableButtons: false,
                isLoaded: false,
                gTasksCount: '',
                filteredTasks: [],
                showFilters: false, 
                tempFilters: [],
                showDismissModal: false,
                showCompleteAllModal: false,
                showDialog: false,
            }
        },
        async created() {
            EventBus.$emit('priority_action');
            EventBus.$on('hide_drawer', this.close);            
            //this.fetchPending();
            
           // if(!this.tasksCenterOpen){
            this.getTasks({ role_ids: this.rolesIDs }); 
            // }
            this.setTaskCenterOpen(true);
            this.setTaskCounts({ role_ids: this.rolesIDs }); 

        },
        computed:{
            ...mapGetters({
                sections: 'taskCenterStore/sections',
                buttons: 'taskCenterStore/buttons',
                headers: 'taskCenterStore/headers',
                tasks: 'taskCenterStore/tasks',
                eventTypes: 'taskCenterStore/eventTypes',
                tasksCount: 'taskCenterStore/tasksCount',
                activeSection: 'taskCenterStore/activeSection', 
                activeSectionName: 'taskCenterStore/activeSectionName',
                subcategory: 'taskCenterStore/subcategory',
                numTasks: 'taskCenterStore/numTasks',
                filters: 'taskCenterStore/filters',
                tasksCenterOpen: 'taskCenterStore/tasksCenterOpen',
                activeDrawerComponent: 'navigationStore/activeDrawerComponent'
            }),
           currentDate(){
                return  moment().format('M/DD/YYYY'); 
            },
            uniqueEvents(){
                let arr = [];
                this.eventTypes.forEach((el, index) => {
                let found = arr.find( a => a.name == el.name );
                if (!found) {
                    arr.push( { id: el.id, name: el.name } )
                }
                });
                return arr.sort((a, b) => a.name.localeCompare(b.name));
            },
            isLockCategory(){
                if(!this.subcategory) return false;
                let et = this.eventTypes.find(et => et.id === this.subcategory);
                if(!et) return false;
                return et.slug === "overlock_space" || et.slug === "lock_removal"
            }
        },
        methods: {
            ...mapActions({
                getTasks: 'taskCenterStore/getTasks',
                //getEvents: 'taskCenterStore/getEvents',
                getEventTypes: 'taskCenterStore/getEventTypes',
                // getStoreTasksCount: 'taskCenterStore/getTasksCount',
                resetTasks: 'taskCenterStore/resetTasks',
                getGroupTasks: 'taskCenterStore/getGroupTasks', 
                setActiveSection: 'taskCenterStore/setActiveSection',
                setTaskCenterOpen: 'taskCenterStore/setTaskCenterOpen', 
                setTaskCounts: 'taskCenterStore/setTaskCounts', 
                setFilters: 'taskCenterStore/setFilters',
                setSubcategory: 'taskCenterStore/setSubcategory', 
                setActiveDrawerComponent: 'navigationStore/setActiveDrawerComponent'
            }),
            async completeAllConfirm(){
                if(!this.subcategory) return false;
                let et = this.eventTypes.find(et => et.id === this.subcategory);
                
                if(et.slug === "overlock_space"){
                    await this.$store.dispatch('taskCenterStore/overlockAllSpaces');
                } else if (et.slug === "lock_removal"){
                    await this.$store.dispatch('taskCenterStore/removeAllOverlocks');
                }
                this.showCompleteAllModal = false;
            },
            completeAll(){
                this.showCompleteAllModal = true;
            },

            async downloadReport(){
                let subcategory = this.eventTypes.find(e => e.id ===this.subcategory)
                
                let response = await api.post(this, api.REPORTS +  'download-pdf', {
                    type: subcategory.slug
                }); 
                
                if(response.socket) {
                    const payload = { 
                        type: 'document_download', 
                        title: 'Generating Document',
                        persistent: true,
                        loading: true,
                        description: 'We are generating your document, it should begin downloading shortly.'
                    }
                    this.showMessageNotification(payload);
                } else {
                  const arr = new Uint8Array(response.data);
                  var blob = new Blob([arr], {type: 'application/pdf' });
                  var link = document.createElement('a');
                  link.href = window.URL.createObjectURL(blob);
                  link.download =  subcategory.name + '.pdf';
                  link.click();
                }

            }, 
            async moveBack(){
                if(this.subcategory){
                    this.setSubcategory(null)
                } else {
                    this.setActiveSection('all');
                    await this.getTasks({reset: true, role_ids: this.rolesIDs});
                    this.setActiveDrawerComponent({ type: 'show_task_center', active_section: 'all' });

                }
            }, 
            closeFilter(){
                this.showFilters = false;
                this.tempFilters = [];
            },
            showTaskDialog(){
                this.closeFilter();
                setTimeout(() => {
                    this.showDialog = true;
                }, 20);
            }, 
            close(exit=true) {
                console.log("exit", exit)
                if(exit){
                    this.setTaskCenterOpen(false);
                }
                this.$emit('close');
            },
            applyFilters(fetchCounts = false){
                this.showFilters = false;
                this.setFilters(this.tempFilters);

                this.getTasks({ reset: true, role_ids: this.rolesIDs });
                this.setActiveDrawerComponent({type: 'show_task_center', ...this.activeDrawerComponent, filters: this.tempFilters });
                // this.getTaskData(fetchCounts);
            },
            getEventName(id){
                let evt =  this.uniqueEvents.find(e => e.id == id);
                if(!evt) return null; 
                return evt.name; 
            },
          refetchData(id){            
                this.setFilters(this.filters.filter(f => f !== id));
                this.isScrollRequest = false;
                this.getTasks({ reset: true, role_ids: this.rolesIDs });
                this.setActiveDrawerComponent({type: 'show_task_center', ...this.activeDrawerComponent, filters: [] });
            },

            // validateTask(taskData){
            //     let taskObj = {
            //         todo: taskData.task
            //     };
            //     let task    = taskData.task;
 
            //     if (taskData.type === 'group' && taskData.Tasks && taskData.Tasks.length) {
            //         taskObj.grouped_tasks = taskData.Tasks;
            //     }

            //     if (
            //         task
            //         && Object.keys(task).length > 0
            //         && task.Event
            //         && Object.keys(task.Event).length
            //         && task.Event.event_type
            //         && Object.keys(task.Event.event_type).length) {
            //             taskObj.event_type = task.Event.event_type;
            //             if (task.Event.Contact && Object.keys(task.Event.Contact).length) {
            //                 taskObj.contact = task.Event.Contact;
            //             }
            //             if (task.Event.Lease) {
            //                 taskObj.unit = task.Event.Lease.Unit;
            //                 taskObj.lease_id = task.Event.Lease.id;
            //                 if (!taskObj.contact && task.Event.Lease.Tenants && task.Event.Lease.Tenants.length && task.Event.Lease.Tenants[0].Contact) {
            //                     taskObj.contact = task.Event.Lease.Tenants[0].Contact;
            //                 }
            //             }
            //     }
            //     return ( !taskObj.grouped_tasks && Object.keys(taskObj).length > 0 ) || ( taskObj.grouped_tasks && Object.keys(taskObj).length > 1 ) ? taskObj : null;
            // },
            fetchPending(){
                api.get(this, api.LEASES +  'pending').then( r => {
                    this.isLoaded = true;
                    this.pending = r.leases.map(l => {
                        l.start_date = moment(l.start_date, 'YYYY-MM-DD').startOf('day').valueOf();
                        l.end_date = l.end_date ? moment(l.end_date , 'YYYY-MM-DD').startOf('day').valueOf() : null;
                        l.time = 0;
                        l.contact = l.Tenants.length ? l.Tenants[0].Contact : {};
                        l.unit = l.Unit;
                        l.task_type = 'moveIn'
                        return l;
                    });
                });
            },
            
            // searchNextList({ target: { scrollTop, clientHeight, scrollHeight }}){
            //     let taskCheck = this.counts[this.activeView] > this.formattedTasks.length ? true : false;
            //     if (scrollTop + clientHeight >= scrollHeight) {
            //          if ((this.tempTask.length%20) === 0 && !this.isLoading(this.$options.name) && taskCheck) {
            //             this.getTaskData();
            //         }
            //     }
            // },
            // getTasksCount(filterEventIds = []){
            //     this.filteredTasks = filterEventIds;
            //     let ids = Object.assign({}, this.urlEventIds);
            //     ids.filterIds = this.filteredTasks;
            //     let param = ''
            //     Object.values(ids).forEach( (elem, index) => {
            //         elem.forEach( (id, index) => {
            //             param = param + `event_type_id[]=${id}&`;
            //         });
            //     });
            //     param = param.slice(0, -1);
            //     param = `?${param}`;
            //     this.getStoreTasksCount(param);
            // },
            // getButtonTasksCount(){
            //     let list = this.sections.map(s => s.task_categories).filter(i => i && i.length);
            //     let names = Array.prototype.concat.apply([], list);
            //     this.setCountsByName(names)

                // let list = this.sections.map(s => {

                //     if(!s.task_categories) return []; 
                    
                //     let types = s.task_categories.map(tc => {
                //         let type = this.eventTypes.find(e => e.slug === tc)   
                //         return type ? type.id : ''
                //     })
                //     return types;
                // })


                // let params = Array.prototype.concat.apply([], list).filter(i => i.length).reduce((a,b) => a + `&event_type_id[]=${b}`, ''); ;
                // console.log("getButtonTasksCount", params)
                // this.getStoreTasksCount(params);
            // },
            // setIds(){
            //     let types = this.eventTypes;
            //     Object.keys(this.urlEvents).forEach((key, index) => {
            //         let arr = [];
            //         Object.values(this.urlEvents[key]).forEach( ename => {
            //             let found = types.find( arrEl => arrEl.slug == ename);
            //             if (found) {
            //                 arr.push(found.id);
            //             }
            //         });
            //         this.urlEventIds[key] = arr;
            //     });
            //     if (types && types.length) {
            //         this.getTasksCount();
            //     }
            // },
            // async getTaskData(){
            //     let param = '';
            //     if (this.activeView !== 'seeList') {
            //         Object.values(this.urlEventIds[this.activeView]).forEach( (id, index) => {
            //             param = param + `event_type_id[]=${id}&`;
            //         });
            //         param = param.slice(0, -1);
            //     }
            //     let data = {
            //         component: this,
            //         params: `?limit=20&offset=${this.tempTask.length}&${param ? param : ''}`,
            //     }
            //     if (this.activeView === 'seeList') {
            //         await this.getGroupTasks(data);
            //     } else {
            //         this.getTasks(data);
            //     }
            // },
            // showSeeListView(tasksCount){
            //     this.activeView = 'seeList';
            //     this.gTasksCount = tasksCount;
            // },
            // fetchTasks(){
            //     this.activeView = 'taskCenter';
            //     this.getTasksCount();
            // }
        },
        components: {
            Header,
            TaskItem,
            Buttons,
            TasksList,
            ToDo
        },
        watch: {
            // activeView(value){
            //     if (value !== 'view') {
            //         if (value === 'moveIn') {
            //             this.fetchPending();
            //         }
            //         else if (value !== 'seeList') {
            //             this.getTasks();
            //         }
            //     } else {
            //         this.tempTask = [];
            //         this.resetTasks();
            //     }
            // },
            // eventTypes(value) {
            //     if (value) {
            //    //     this.setIds();
            //     }
            // }
        },
        destroyed() {
            // this.resetTasks();
            EventBus.$off('hide_drawer', this.close);
        },
    }
</script>

<style scoped lang="scss">
    .bs-none{
        box-shadow: none !important;
    }

    .tasks-counter{
        background-color: #F26500;

        > div{
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 14px;
            color: #fff;

            small {
                font-style: normal;
                font-size: 10px;
                line-height: 16px;
                font-weight: 500;
            }
        }
    }
    .task-calender{
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #101318;
    }
</style>
