<template>
    <div>
        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

        <div>
            <v-container no-gutters class="pr-0 mb-0">
                <v-row no-gutters>
                    <v-col md="5" no-gutters>
                        <div class="hb-font-header-2-medium pt-1">Select Merchandise</div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="text-right mr-0 pr-5" md="5">
                        <hb-text-field
                            search
                            placeholder="Search"
                            v-model="searchProduct"
                        >
                        </hb-text-field>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <v-container class="insurance-container d-flex flex-wrap scrollable-content scroll-products pa-0 pb-2 position-relative">
            <async-loader v-if="isLoadingProducts" style="left: 45%;" />
            <div v-for="product in productsData" :key="product.id" v-else class="col-4 pa-0">
                <hb-card class="ma-1 pa-2">
                    <img v-if="product.image" style="max-width: 100%"  :src='"data:image/" + product.image.image_type + ";base64," + product.image.data_url' />
                    <div class="placeholder-img" v-else>
                        <hb-icon color="#A5B4BF">mdi-image-area</hb-icon>
                        <div class="light-color hb-button-text-small pt-2">No Image Available</div>
                    </div>

                    <!-- <img :src="product.images[0]" alt="" style="max-width: 222px;" srcset=""> -->
                    <div class="d-flex justify-space-between mt-2 mb-3">
                        <div class="font-14px font-weight-medium product-title" @click="additemToCart2(product)">{{ product.title }}</div>
                        <p class="mb-0">${{ product.price }}</p>
                    </div>
                    <div class="product-description hb-text-light hb-button-text-small">
                        <span v-if="product.description">
                            {{ product.description | truncateText(100) }}
                        </span>
                        <span v-else class="light-color">No description available</span>
                    </div>
                    <div class="product-actions mt-3">
                        <div class="stock-available  hb-button-text-small mb-2" :class="[ product.stock < 0 ? 'danger-color' : 'hb-text-light']">
                            {{ product.stock }} in stock
                        </div>
                        <div class="d-flex justify-space-between align-center">
                            <div class="stock-btns d-flex align-center justify-space-between px-2" style="width: 100px; height:32px; border: 1px solid #DFE3E8; border-radius: 5px;">
                                <!-- <hb-btn icon>mdi-minus</hb-btn> -->
                                <hb-icon color="#000" small @click="product.qty=product.qty-1" class="hand">mdi-minus</hb-icon>
                                <span>{{ product.qty }}</span>
                                <hb-icon color="#000" small @click="product.qty=product.qty+1" class="hand">mdi-plus</hb-icon>
                            </div>
                            <hb-btn color="secondary" :disabled="!product.allow_buy" width="100px" :class="{'not-allowed': !product.allow_buy}" @click="additemToCart2(product)">
                                {{ services[product.id] && services[product.id].product_id ? 'Update' : 'Add' }}
                            </hb-btn>
                        </div>
                    </div>
                </hb-card>
            </div>
        </v-container>


        <div class="new-tenant-walkthrough-footer">
            <hb-bottom-action-bar
                @close="$emit('close')"
                :top-border="false"
            >
                <template v-slot:left-actions>
                    <help-juice />
                </template>
                <template v-slot:right-actions>
                    <hb-btn color="secondary" v-if="contact.national_account_id && !quick_action" :disabled="isLoading($options.name) || !canProgress || isLoadingProducts" @click="saveInvoice">Skip Payment</hb-btn>
                    <hb-btn color="primary" :disabled="isLoading($options.name) || !canProgress || isLoadingProducts" @click="productsSaved">Checkout</hb-btn>
                </template>
            </hb-bottom-action-bar>
        </div>
    </div>

</template>

<script type="text/babel">
    import api from '../../../assets/api.js';
    import Status from './../Messages.vue';
    import Loader from '../../assets/CircleSpinner.vue';
    import moment from 'moment';
    import { notificationMixin } from  '../../../mixins/notificationMixin.js';
    import AsyncLoader from '../../includes/Loading.vue';
    import Insurance from './Insurance';
    import Merchandise from './Merchandise';
    import Fees from './Fees';
    import { mapGetters, mapActions, mapMutations} from 'vuex'
    export default {
        name: 'ServicesSelection',
        mixins: [ notificationMixin ],
        components: {
            Status,
            Insurance,
            Merchandise,
            Fees,
            AsyncLoader
        },
        data: () => ({
            active_tab: 'merchandise',
            panel: 0,
            confirm: false,
            show_security_deposit: false,
            promotions:[],
            unit_id: '',
            products: [],
            searchProduct: '',
            isLoadingProducts: false
        }),
        props: ['property', 'services', 'lease_id', 'contact', 'quick_action'],

        created(){
          if (this.property?.id) {
            this.fetchProducts();
          }
        },
        computed:{
            ...mapGetters({
                activeDrawerComponent: 'navigationStore/activeDrawerComponent'
            }),
            canProgress(){
                for(let s in this.services){
                  if(this.services[s] && this.services[s].qty && this.services[s].price  ) return true;
                }
                return false;
            },
            merchandise(){
                return this.products.filter(p => p.default_type === 'product').map(p => {
                    p.qty = this.services[p.id]?.qty ? this.services[p.id].qty : '';
                    p.price = this.services[p.id]?.price ? this.services[p.id].price : p.price;
                    return p;
                })
            },
            fees(){
                return this.products.filter(p => p.default_type === 'late')
            },
            productsData() {
                let searchValue = this.searchProduct;
                let tempProducts = searchValue && searchValue.trim() ? this.products.filter(p => p.title.toLowerCase().includes(searchValue.toLowerCase())) : this.products;
                return tempProducts;
            }
        },
        methods:{
            ...mapMutations({
                setActiveDrawerComponent:'navigationStore/SET_ACTIVE_DRAWER_COMPONENT',
            }),
            additemToCart2(params, quntity = 1){
                console.log(params.allow_negative, params.qty, params.stock, params.qty > params.stock)
                if (!params.allow_negative && params.qty > params.stock) {
                    this.showMessageNotification({ description: `Sorry, you've requested more items than we have in stock. Please adjust your order.` })
                    return;
                }
                let product = this.services[params.id];
                var data = {
                    product_id: params.id,
                    name: params.title,
                    price: params.price,
                    qty: parseInt(product ? product.qty + params.qty : params.qty),
                    recurring: 0,
                    type: 'product',
                    prorate: 0,
                    description: params.description,
                    start_date: moment().format('YYYY-MM-DD'),
                    end_date: moment().format('YYYY-MM-DD'),
                    taxable:params.taxable
                };
                this.$set(this.services, params.id, data);
            },
            async productsSaved(){
                if (this.property.id) {
                    if (Object.keys(this.services).length) {
                        this.services.skip = false
                        this.$emit('setServices', this.services);
                    } else {
                        this.showMessageNotification({ description: 'Please select at least one product' })
                    }
                } else {
                    this.showMessageNotification({ description: 'Please select property first' })
                }
            },
            async saveInvoice() {
                this.services.skip = true
                 this.$emit('setServices', this.services);
            },

            async fetchProducts(){
                this.isLoadingProducts = true;
                let r = await api.get(this, api.PROPERTIES + this.property.id + '/products?type=product');
                try {
                    let stockedProducts = r.products.filter(item => item.summary_inventory && item.summary_inventory.remaining_inventory);
                    stockedProducts = stockedProducts.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }))
                    let unstockedProducts = r.products.filter(item => !item.summary_inventory || !item.summary_inventory.remaining_inventory)
                    unstockedProducts = unstockedProducts.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }))
                    this.products = [...stockedProducts, ...unstockedProducts];
                    this.products = this.products.map(item => {
                    return {
                        id: item.id,
                        title: item.name,
                        description: item.description,
                        price: item.price,
                        // stock: item.summary_inventory ? item.summary_inventory.remaining_inventory : 'Not',
                        stock: item.summary_inventory ? item.summary_inventory.remaining_inventory : 'Not',
                        allow_buy: item.summary_inventory ? true : false,
                        allow_negative: item.merchandise_details?.negative_inventory ? true : false,
                        image: item.merchandise_details?.images && item.merchandise_details?.images.length ? item.merchandise_details?.images[0] : '',
                        qty: 1,
                        taxable:item.taxable ? true : false
                    }
                });
                } catch (error) {
                    this.showMessageNotification({ description: error });
                } finally {
                    this.isLoadingProducts = false;
                }
            }
        },
        watch: {
            services() {
                this.setActiveDrawerComponent({ ...this.activeDrawerComponent, services: this.services});
            }
        },
    }
</script>

<style scoped>
    .tab-container{
      padding: 0 24px;
      background: none;
      border-bottom: 1px solid #E1E6EA
    }
    .new-tenant-walkthrough .v-tabs,
    .new-tenant-walkthrough .v-tabs .v-tabs-bar{
      background: #F9FAFB;
    }
    .new-tenant-walkthrough-footer{
        padding: 3px 0 3px 30px;
        background: #FFFFFF;
        border-top: 1px solid #e2e2e2;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 100;
        left: 0;
        right: 0;
    }
    .services-section{
        padding: 0px 24px 100px 24px;
    }

    .unit-confirm-icon .v-list-item__icon {
        text-align: center;
        background: white;
        padding: 25px;

    }

    .v-expansion-panel-content .form-label{
        background: #F4F6F8;
        padding: 20px;
        font-weight: bold;
    }
    .row {
        margin-bottom: 10px;
    }

    .scroll-products {
        height: calc(100vh - 450px);
    }

    .ellipsis-multiline {
        position: relative;
        max-height: 4.5em; /* Adjust based on the number of lines (line-height * number of lines) */
        overflow: hidden;
        padding-right: 1em; /* Space for the fade effect */
        line-height: 1.5em; /* Adjust based on your font-size */
    }

    .ellipsis-multiline::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        width: 1em;
        height: 1.5em; /* Match the line-height */
        background: linear-gradient(to right, transparent, white 50%);
    }

    .element-aa {
        display: table;
        table-layout: fixed;
        width: 100%;
    }

    .truncate-a {
        display: table-cell;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .product-description {
        min-height: 55px;
    }

    .product-title {
        min-height: 42px;
    }

    .placeholder-img {
        width: 100%;
        min-height: 222px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #F9F9F9;
    }

    .light-color {
        color: #A5B4BF;
    }

    .not-allowed {
        cursor: not-allowed;
    }

</style>
