<template>
    <div>
        <payment-process 
            :contactID="contact_id" 
            :propertyID="property_id"
            :transfer_lease_id="lease_id"
            paymentSource="TRANSFERS">
        </payment-process>
        <div class="new-tenant-walkthrough-footer">
            <v-row class="ml-0">
                <v-col class="pt-4">
                    <help-juice />
                </v-col>
                <v-col cols="9" class="d-flex align-center justify-end pr-sm-7">
                    <hb-link class="mr-2" @click="$emit('close')"
                        >Cancel</hb-link
                    >
                    <hb-btn v-if="inCall && ivrPaymentType === 'card' &&  IvrPaymentEnable && ivrPaymentMethod == 'new' " class="mr-2" color="primary" @click="transferSpace" :disabled="getDisablePaymentButton"
                    >Confirm Transfer  {{ivrTimeout}}</hb-btn>
                    <hb-btn v-else class="mr-2" color="primary" @click="transferSpace" :disabled="getDisablePaymentButton"
                        >Confirm Transfer</hb-btn
                    >
                </v-col>
            </v-row>
        </div>
        <hb-modal confirmation v-model="dialog" size="medium" title="Execute Transfer" show-help-link>
            <template v-slot:content
                >
                <div class="px-6 py-4 font-weight-medium">
                    Are you sure you want to continue?
                </div>
                <div class="px-6 pb-4">
                    If you continue past this point, you will be executing the transfer and the space your tenant is going to transfer out of will be immediately put back into inventory. Please make sure to vacate and clean the space as soon as possible.
                </div>
            </template>
            <template v-slot:actions>
                <hb-btn v-if="inCall && ivrPaymentType === 'card' &&  IvrPaymentEnable && ivrPaymentMethod == 'new' " color="primary" @click="completeSession()" :disabled="!enableIvrProcessPayment" :loading="ivrPaymentButtonLoading">Confirm  {{ivrTimeout}}</hb-btn>
                <hb-btn v-else color="primary" @click="checkPaymentsErrors" :disabled="isLoading($options.name) || disable_payment" :loading="isLoading($options.name) || disable_payment">Confirm</hb-btn>
            </template>
        </hb-modal>

        <payments-notification-modal
            v-model="notificationModal"
            notificationType="accept_payments"
        >
        </payments-notification-modal>
    </div>
</template>

<script type="text/babel" >
import PaymentProcess from '../PaymentProcess/Index.vue';
import api from "../../../assets/api.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { EventBus } from '../../../EventBus.js';
import { notificationMixin } from  '../../../mixins/notificationMixin.js';
import PaymentsNotificationModal from '../../payments/PaymentsNotificationModal.vue';
import axios from 'axios';
import https from 'https';

export default {
    name: "TransferPayments",
    mixins: [ notificationMixin ],
    data() {
        return {
            formErrors: '',
            disable_payment: false,
            // payment_type: 'reader',
            dialog: false,
            notificationModal: false,
            newSpacePin: '',
        };
    },
    props: [
        "close",
        "next",
        "unit_invoice",
        "paymentMethodsData",
        "property_id",
        "contact_id",
        "refundCredit",
        "lease_id",
        "transferDataPayments",
        "autopay_id",
        "isAutoChargeEnrolled",
        "moveOutSpaceAccess",
        "isSpaceAccessCodesVendor",
        "configuration_unit",
        "selectedHours"
    ],
    components: {
        PaymentProcess,
        PaymentsNotificationModal
    },
    async created() {
        EventBus.$on("get_data_for_transfer_charm_ivr",this.getTransferData)
        this.updateIvrPaymentEnable(true)
    },
    destroyed(){
        this.updateIvrPaymentEnable(false)
        EventBus.$off("get_data_for_transfer_charm_ivr",this.getTransferData)
        this.closeMessageNotification();
        EventBus.$emit('set_payment_source');
    },
    computed: {
        inCall() {
              return this.notification.status === 'active';
            },
          ivrTimeout(){
              return this.ivrTimer
          },
        ...mapGetters({
            ivrTimer:'charmCallStore/ivrTimer',
            ivrPaymentButtonLoading:'charmCallStore/ivrPaymentButtonLoading',
            paymentConnectorResponse:'charmCallStore/paymentConnectorResponse',
            enableIvrProcessPayment:'charmCallStore/enableIvrProcessPayment',
            ivrPaymentMethod:'charmCallStore/ivrPaymentMethod',
            IvrPaymentEnable:'charmCallStore/IvrPaymentEnable',
            ivrPaymentType:'charmCallStore/ivrPaymentType',
            notification:'charmCallStore/notification',
            configuration_lease: 'onBoardingStore/configuration_lease',
            getErrors: 'paymentsStore/getErrors',
            hasPermission: 'authenticationStore/rolePermission',
            payment_information: 'paymentsStore/getPaymentInformation',
            getDisablePaymentButton: 'paymentsStore/getDisablePaymentButton'
        }),
        transfer_balance_due() {
            if(this.refundCredit && this.unit_invoice && this.refundCredit > 0) {
                let balance_due = this.unit_invoice.balance - this.refundCredit;
                return (balance_due > 0) ? balance_due : 0;
            } else {
                return  this.unit_invoice ? this.unit_invoice.balance : 0;
            }
        },
        getAutopayId() {
            return this.transferDataPayments && this.transferDataPayments.autopay_id;
        }
    },
    watch: {
       async paymentConnectorResponse(responseData){
        let response = {}
            if(!responseData) return
            if(responseData.status == 200){
                    let transferData = responseData.transfer;
                    let leaseResponse = await api.get(this, api.LEASES + transferData.lease_id);
                    this.$store.dispatch('onBoardingStore/setLease', leaseResponse.lease);
                    this.$store.commit('onBoardingStore/setPayment', {id: transferData.payment_id});

                        response = {
                            data: {
                                msg: {
                                    id: this.$options.name,
                                    text: 'Your Payments have been processed successfully',
                                },
                                payment: {id: transferData.payment_id}
                            }
                        };
                  this.enablePayment(response);
            
                }else{
                    response = {
                        error: responseData?.msg ? responseData?.msg : "Something went wrong"
                    };
                  this.enablePayment(response);
                }
          },
        refundCredit: {
            immediate: true,
            handler(newVal, oldVal) {
                this.setPaymentInformation({
                    property: "transferCredits",
                    propertyValue: newVal
                })
            },
        },
        getErrors(value) {
            if(value === false) {
                this.processPayment();
                this.setCheckErrors({ value: null });
            }
        }
    },
    filters: {},
    methods: {
        async completeSession(){
            EventBus.$emit('complete-ivr-payment-session');
          },
        ...mapActions({
            getTransformedPaymentObject: 'paymentsStore/getTransformedPaymentObject'
        }),
        ...mapMutations({
            updateIvrPaymentEnable:'charmCallStore/updateIvrPaymentEnable',
            setPaymentInformation: 'paymentsStore/setPaymentInformation',
            setPaymentConfigProperty: 'paymentsStore/setPaymentConfigProperty',
            setCheckErrors: 'paymentsStore/setCheckErrors',
            checkOnlyCredit:'paymentsStore/checkOnlyCredit'
        }),
        async enablePayment(response) {

            if(response && response.error) {
                this.showMessageNotification({ list: [response.error] });
                this.dialog = false;
                this.disable_payment = false;
            } else if(response && response.data && response.data.msg) {
                this.showMessageNotification({ type: 'success', description: response.data.msg.text });
                EventBus.$emit('payment_saved');
                EventBus.$emit('refresh_lease_tenants');

                if(this.isSpaceAccessCodesVendor){
                    await this.handleNewPin();

                    if(this.newSpacePin && this.newSpacePin.length === 4){
                        await this.saveNewSpaceCode();
                    }
                    
                    await this.transferCatches();
                }
                
                this.$emit("next");
                this.disable_payment = false;
            }
        },

        async handleNewPin(){
            
            if(this.configuration_unit && this.configuration_unit.number && this.configuration_unit.number.length > 4){
				this.showMessageNotification({ type: 'error', description: 'Unable to transfer or generate pin for the transfer in unit because the transfer in unit number is longer than 4 digits.' });
                return;
            } else if(this.configuration_unit && this.configuration_unit.number && this.configuration_unit.number.length < 5 && this.moveOutSpaceAccess && this.moveOutSpaceAccess.pin && this.moveOutSpaceAccess.pin.length < 8){
                
                await this.generateCode(); 

            } else if(this.configuration_unit && this.configuration_unit.number && this.configuration_unit.number.length < 5 && this.moveOutSpaceAccess && this.moveOutSpaceAccess.pin && this.moveOutSpaceAccess.pin.length === 8){

                this.newSpacePin = this.moveOutSpaceAccess.pin.substr(4);

            }
        
        },

        async generateCode(){
            var data = {
                unit_id: this.configuration_unit.id,
                unit_number: this.configuration_unit.number
            }

            try {

                let r = await api.get(this, api.PROPERTIES + this.property_id + '/generate-space-code', data, 'gate' );

                this.newSpacePin = r.code.substr(4);

            } catch(err) {
                this.showMessageNotification({ description: err });
            };
        },

        async saveNewSpaceCode(){
            var data = {
                pin: this.newSpacePin,
                property_id: this.property_id,
                unit_id: this.configuration_unit.id,
                unit_number: this.configuration_unit.number,
                area_id: this.configuration_unit.area_id
            }

            if(this.moveOutSpaceAccess.contact_access_id){

                try {
                    
                    let r = await api.put(this, api.CONTACTS + this.contact_id + '/access/' + this.moveOutSpaceAccess.contact_access_id, data);

                } catch(err) {
                    this.showMessageNotification({ description: err });
                };

            } else {

                try {
                    
                    let r = await api.post(this, api.CONTACTS + this.contact_id + '/access', data);

                } catch(err) {
                    this.showMessageNotification({ description: err });
                };

            }

        },

        async transferCatches(){
            try {
                var data = {
                    property_id: this.property_id,
                    soft_catch: this.moveOutSpaceAccess.soft_catch && this.moveOutSpaceAccess.soft_catch !== null ? this.moveOutSpaceAccess.soft_catch : 0,
                    late_catch: this.moveOutSpaceAccess.late_catch && this.moveOutSpaceAccess.late_catch !== null ? this.moveOutSpaceAccess.late_catch : 0,
                    hard_catch: this.moveOutSpaceAccess.hard_catch && this.moveOutSpaceAccess.hard_catch !== null ? this.moveOutSpaceAccess.hard_catch : 0
                }

                let r = await api.put(this, api.UNITS + this.configuration_unit.id + '/catches', data);

            } catch(err) {
                this.showMessageNotification({ description: err });
            };
        },

        checkPaymentsErrors() {
            //this.setCheckErrors({ value: true });
            //this function will check if the payment only with credit it will skip the checkErrors. otherwise, if not credit payment it wich check the errors normal.
            this.checkOnlyCredit();
        },
        async getTransferData(){
            const paymentInfo = await this.getTransformedPaymentObject({
                id: this.$options.name,
                formErrors: this.formErrors,
                type:'ivr'
            });
            if(paymentInfo && paymentInfo.payment) {
                const { payment, paymentMethod, contact_id, property_id, leases } = paymentInfo;

                let transferLeases = [...leases];
                if(transferLeases && transferLeases.length){
                    transferLeases[0].auto_charge = null;
                    transferLeases[0].amount = this.transfer_balance_due;
                }

                let transfer_info = {
                    start_date: this.configuration_lease.start_date,
                    end_date: this.configuration_lease.end_date,
                    discount_id: this.configuration_lease.discount_id,
                    // promotions: this.configuration_lease.promotions, 
                    bill_day: this.configuration_lease.bill_day,
                    rent: this.configuration_lease.rent,
                    security_deposit: this.configuration_lease.security_deposit,
                    coupons: this.configuration_lease.coupons,
                    insurance_id: this.configuration_lease.insurance_id,
                    billed_months: this.$store.getters['onBoardingStore/billed_months'],
                    products: this.configuration_lease.products,
                    type: this.configuration_lease.type,
                    unit_id: this.transferDataPayments.unit_in,
                    reason: this.transferDataPayments.reason,
                    notes: this.transferDataPayments.notes,
                    decline_insurance: this.configuration_lease.insurance_id === "" ? '1' : '0'
                }

                if(!this.auto_charge) {
                    transfer_info.auto_pay_id = this.getAutopayId
                }

                if(this.configuration_lease.promotions && this.configuration_lease.promotions.length){
                    transfer_info.promotions = this.configuration_lease.promotions.map( p => {
                        return {promotion_id: p.Promotion.id};
                    })
                }

                let payment_info = {
                    payment: Object.keys(payment).length ? payment : null,
                    paymentMethod: paymentMethod,
                    contact_id: contact_id,
                    property_id: property_id,
                    leases: transferLeases,
                    Invoices: []
                }
            

                let data = Object.assign(payment_info, transfer_info);
                data.lease_id = this.lease_id
                EventBus.$emit('receive_transfer_data_ivr',{paymentInfo:data}) 
            }else{

                EventBus.$emit('receive_transfer_data_ivr',{paymentInfo:paymentInfo}) 
            }
        },
        async fetchPropertyConnections(device_id) {
              let r = await api.get(this.$app, api.PROPERTIES + this.lease.Unit.property_id + "/connections/address?device_id=" + device_id);
              this.IPaddress = r.IPaddress;
              this.lan = r.lan;
        },
        async processPayment() {
            let payloadResponse;
            let lanResponseData;
            let payment_id;
            let responseData;
            this.disable_payment = true;
            const paymentInfo = await this.getTransformedPaymentObject({
                id: this.$options.name,
                formErrors: this.formErrors
            });
            
            if(paymentInfo.errors && paymentInfo.errors.length) {
                this.showMessageNotification({ list: paymentInfo.errors });
                this.disable_payment = false;
                return;
            }

            if(paymentInfo && paymentInfo.payment) {
                const { payment, paymentMethod, contact_id, property_id, Invoices, use_credits, leases, auto_pay_leases } = paymentInfo;

                let transferLeases = [...leases];
                if(transferLeases && transferLeases.length){
                    transferLeases[0].auto_charge = null;
                    transferLeases[0].amount = this.transfer_balance_due;
                }

                let transfer_info = {
                    start_date: this.configuration_lease.start_date,
                    end_date: this.configuration_lease.end_date,
                    discount_id: this.configuration_lease.discount_id,
                    // promotions: this.configuration_lease.promotions, 
                    bill_day: this.configuration_lease.bill_day,
                    rent: this.configuration_lease.rent,
                    security_deposit: this.configuration_lease.security_deposit,
                    coupons: this.configuration_lease.coupons,
                    insurance_id: this.configuration_lease.insurance_id,
                    billed_months: this.$store.getters['onBoardingStore/billed_months'],
                    products: this.configuration_lease.products,
                    type: this.configuration_lease.type,
                    unit_id: this.transferDataPayments.unit_in,
                    reason: this.transferDataPayments.reason,
                    notes: this.transferDataPayments.notes,
                    decline_insurance: this.configuration_lease.insurance_id === "" ? '1' : '0'
                }

                if(!this.auto_charge) {
                    transfer_info.auto_pay_id = this.getAutopayId
                }

                if(this.configuration_lease.promotions && this.configuration_lease.promotions.length){
                    transfer_info.promotions = this.configuration_lease.promotions.map( p => {
                        return {promotion_id: p.Promotion.id};
                    })
                }

                let payment_info = {
                    payment: Object.keys(payment).length ? payment : null,
                    paymentMethod: paymentMethod,
                    contact_id: contact_id,
                    property_id: property_id,
                    leases: transferLeases,
                    Invoices: []
                }

                let data = Object.assign(payment_info, transfer_info);
                let response = {};

                if(payment_info?.paymentMethod?.device_id){
                    await this.fetchPropertyConnections(payment_info.paymentMethod.device_id);
                }
                try{
                    if(payment_info.paymentMethod && payment_info.paymentMethod.device_id && this.lan == 1){
       

                        // Create an instance of the HTTPS agent with certificate verification enabled.
                                const certData = ``;
                        
                                const httpsAgent = new https.Agent({
                                ca: [certData],
                                });
                        
                                // Configure Axios to use the custom HTTPS agent.
                                const instance = axios.create({
                                httpsAgent
                                });
                                const endpoint = 'https://' + this.IPaddress + '?';
                                //Get PAX Request Payload from HB Backend server
                                payloadResponse = await api.post(this, api.PAYMENTS + "getLanSwipePayload",paymentInfo);
                                payment_id = payloadResponse.payment.id;
                                payment_info.payment.id = payment_id;
                                // Make the GET request using the custom Axios instance.
                                lanResponseData = await instance.get(endpoint + payloadResponse.payment.status_desc , {headers: {'Accept': 'text/plain',},});
                                payment_info.payment.PAXResponse = lanResponseData;
                                //console.log("lanResponseData :::",lanResponseData);
                                data = Object.assign(payment_info, transfer_info);
                                let responseData = await api.post(this, api.LEASES + this.lease_id + '/transfer', data);
                    } 
                    else {
                                    let responseData = await api.post(this, api.LEASES + this.lease_id + '/transfer', data);
                                    let transferData = responseData.transfer;
                                    let leaseResponse = await api.get(this, api.LEASES + transferData.lease_id);
                                    this.$store.dispatch('onBoardingStore/setLease', leaseResponse.lease);
                                    this.$store.commit('onBoardingStore/setPayment', {id: transferData.payment_id});

                                        response = {
                                            data: {
                                                msg: {
                                                    id: this.$options.name,
                                                    text: 'Your Payments have been processed successfully',
                                                },
                                                payment: {id: transferData.payment_id}
                                            }
                                        };
                                    }

                }catch(err){
                    response = {
                        error: err
                    };
                    if(payment_info.paymentMethod && payment_info.paymentMethod.device_id && this.lan == 1)
                  {
                    responseData = await api.post(this, api.PAYMENTS + payment_id + "/lanSwipeResponse", response);
                  }
                    
                } finally{
                    this.enablePayment(response);
                }
            }
        },
        transferSpace() {
            if (!this.hasPermission("accept_payments") && this.payment_information.totalNewPaymentPaying > 0){
                this.notificationModal = true;
            } else {
                this.dialog = true
            }
        }
    },
};
</script>

<style scoped>
.info-panel {
    flex: 1 0;
    padding: 30px 50px;
    background-color: #F9FAFB;
    border-left: 1px solid #E1E6EA;
}
.payment-data{
    background: white;
    margin-bottom: 10px;
    border-radius: 1px;
  }
.invoice_display{
    padding:  10px 0px;
    font-size: 14px;
  }

.invoice-line-row{
    margin-bottom: 0;
    padding: 0px 20px;
  }
  .invoice-line-row .col {
    padding: 0;
  }

  .invoice-total-row >.col{
    padding: 10px;
  }

  .invoice-row .caption{
    color: #637381
  }

  .invoice-row > .col{
    padding-bottom: 0;
  }

  .invoice-line-row.total{
    border-top: 1px solid #C4CDD5;
    margin-top: 20px;
  }
  .invoice-line-row.total > .col{
    padding-top: 15px;
  }

  .v-expansion-panel-content .form-label{
    background: #F4F6F8;
    padding: 20px;

  }

  .v-expansion-panel-content .form-label-active{
    background: #EFFAFA;
    padding: 20px;

  }

  .v-expansion-panel-content .form-label-active h3{
      color: #00848E;
  }
</style>